import './404.css';
import React, { useEffect, useState } from 'react';

export default function NotFound() {
    return(
        <div className="main notfound">
            <div>
                <div>
                    <span>ERROR</span>
                    <h2>404</h2>
                    <h3>NOT FOUND</h3>
                </div>
                <p>헉ㄱ... 문제가 있는 것 같아요...<br/>일단 뒤로가기를 눌러보는 게 어떨까요?</p>
            </div>
            <div>
                <img src="/image/adsf자산-2@8x-복사-복사-768x295.png"/>
            </div>
        </div>
    )
}