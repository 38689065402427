import './Main.css';
import React, { useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact() {
    const form = useRef();

    useEffect(() => {
        const input = document.querySelectorAll("input[type='email']");
        input.forEach((e) => e.addEventListener("keyup", addressCheck));

        const submit = document.querySelector("#contact-submit");
        submit.onclick = () => {return addressCheck()};
    }, []);

    function sendEmail(e) {
        e.preventDefault();
        console.log(form);
        emailjs.sendForm('service_zs2bfka', 'template_nd5v5i9', form.current, 'fgZXV25VpiRmxetTZ').then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }

    function addressCheck() {
        const email = document.querySelector("#contact-email");
        const confirm = document.querySelector("#contact-email-confirm");

        const msg = [];
        let isIncorrect = false;

        if (!(email.value.includes("@") && email.value.includes("."))) {
            msg.push("이메일 형식이 맞지 않습니다!");
            isIncorrect = true;
        }
        if (email.value !== confirm.value) {
            msg.push("이메일이 일치하지 않습니다!");
            isIncorrect = true;
        }
        if (!isIncorrect) {
            msg.push("<i class='fa-solid fa-circle-check'></i>");
        }

        const target = document.querySelector("#email-error")
        target.innerHTML = msg.join(" ");
        target.className = isIncorrect ? "error" : "correct";

        return !isIncorrect;
    }

    return(
        <div className="main">
            <div id="contact">
                <h2 id="contact-title">CONTACT</h2>
                <p id="contact-tooltip">아래 서식을 채워주시거나 ellixgraphic@gmail.com으로 메일을 보내주세요!<br/>Please fill out the form below or send an email to ellixgraphic@gmail.com!</p>
                <form id="contact-form" ref={form} onSubmit={sendEmail}>
                    <label htmlFor="contact-category">문의 종류 (Category) <span>*</span></label>
                    <select id="contact-category" name="category">
                        <option value="커미션">커미션 (Commission)</option>
                        <option value="커미션">외주 (Outsource)</option>
                        <option value="커미션">라이센스 (License)</option>
                        <option value="커미션">협업 (Collaborate)</option>
                        <option value="커미션">기타 (etc.)</option>
                    </select>
                    <label htmlFor="contact-name">이름 / 직함 (Name) <span>*</span></label>
                    <input id="contact-name" name="name" type="text" required/>
                    <label htmlFor="contact-email">이메일 (e-mail) <span>*</span> <span id="email-error"></span></label>
                    <div>
                        <div><input id="contact-email" name="email" type="email" placeholder="example@email.com" required/><span>이메일</span></div>
                        <div><input id="contact-email-confirm" type="email" placeholder="example@email.com" required/><span>이메일 확인</span></div>
                    </div>
                    <label htmlFor="contact-comments">남기실 말씀 (Comments)</label>
                    <textarea id="contact-comments" name="comments"></textarea>
                    <input id="contact-submit" type="submit" value="제출 (Submit)"/>
                </form>
            </div>
        </div>
    )
}