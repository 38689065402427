import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, BrowserRouter as Router, Route, Navigate } from 'react-router-dom';

import './index.css';

import Nav from './Header';
import Footer from './Footer';
import Home from './home/Main';
import Mcmap from './home/Mcmap';
import Ellix from './ellix/Main';
import Gear from './ellix/Gear';
import Works from './works/Main';
import Contact from './contact/Main';
import NotFound from './404';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Router>
		<Nav/>
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/ellix" element={<Ellix />} />
			<Route path="/works" element={<Works />} />
			<Route path="/contact" element={<Contact />} />
			<Route path="/ellixgear" element={<Gear />} />
			<Route path="/mcmap" element={<Mcmap />} />
			<Route path="/*" element={<NotFound />} />
		</Routes>
		<Footer/>
  </Router>
);
