import './Header.css';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

export default function Nav() {
	const location = useLocation();
	useEffect(() => {
		navBarListener();
		controlTitle(location);
	}, [location]);

	const [title, setTitle] = useState("WELCOME TO <strong>SPACE.</strong>");

	function controlTitle() {
		switch(location.pathname) {
			case "/ellix":
				setTitle("<strong>SPACE</strong> TRAVELER.");
				break;
			case "/works":
				setTitle("MADE IN <strong>SPACE.</strong>");
				break;
			case "/contact":
				setTitle("SIGNAL TO <strong>SPACE.</strong>");
				break;
			default:
				setTitle("WELCOME TO <strong>SPACE.</strong>");
				break;
		}
	}

	function navBarControl() {
		const body = document.querySelector("#root");
		const nav = document.querySelector("#main-header");
		if (body.scrollTop > 125) {
			nav.classList.add("abs");
		}else {
			nav.classList.remove("abs");
		}
	}

	function navBarListener() {
		const body = document.querySelector("#root");
		body.addEventListener("scroll", navBarControl);
	}

  	return (
		<header id="main-header">
			<h1 id="title" ><Link to="/" activeclassname="active"><img src="/image/ellix space logo.svg"/></Link></h1>
			<div id="main-nav-wrapper">
				<nav id="main-nav">
					<NavLink to="/" activeclassname="active">HOME</NavLink>
					<NavLink to="/ellix" activeclassname="active">ELLIX</NavLink>
					<NavLink to="/works" activeclassname="active">WORKS</NavLink>
					<NavLink to="/contact" activeclassname="active">CONTACT</NavLink>
					<h2 id="main-catch" dangerouslySetInnerHTML={{__html: title}}/>
				</nav>
			</div>
		</header>
  	);
}