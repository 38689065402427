import './Main.css';
import React, { useEffect, useState } from 'react';

export default function Ellix() {
    const [age, setAge] = useState(20);

	useEffect(() => {
        calcAge();
        const root = document.querySelector("#root");
        root.addEventListener("wheel", scrolling);
        return() => {
            root.removeEventListener("wheel", scrolling);
        }
	}, []);

    function scrolling(e) {
        if (e.deltaY < 0) return;
        const root = document.querySelector("#root");
        if (root.scrollTop + 120 < window.innerHeight) {
            e.preventDefault();
            root.scroll({top: window.innerHeight - 50, left: 0, behavior: "smooth"});
        }
    }

    function calcAge() {
        const today = new Date();
        const birthday = new Date(1999, 2, 3);

        let ages = today.getFullYear() - birthday.getFullYear();
        const m = today.getMonth() - birthday.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) ages--;

        setAge(ages);
    }

    return(
        <div className="main">
            <div id="ellix-main">
                <div>
                    <img src="/image/ELLIX LOGO.svg"/>
                </div>
                <div>
                    <img src="/image/ELLIX TEXT LOGO.svg"/>
                </div>
                <div>
                    <img src="/image/ELLIX SCROLL.gif"/>
                </div>
            </div>
            <div id="ellix-catch">
                <h3>MAKING NEW <span>X</span>PERIENCE</h3>
                <h2>ELLIX</h2>
                <p><span>ELLI</span>S <span>X</span>PERIENCE</p>
            </div>
            <div id="ellix-info">
                <h4 className="ellix-title">About<span>.</span></h4>
                <ul className="ellix-item-list">
                    <li><span className="ellix-item-header">Nickname</span><span className="ellix-item-body"><strong>ELLIX</strong></span></li>
                    <li><span className="ellix-item-header">Othername</span><span className="ellix-item-body">Ellis Kim</span></li>
                    <li><span className="ellix-item-header">Born</span><span className="ellix-item-body">03.03.1999</span></li>
                    <li><span className="ellix-item-header">Age</span><span className="ellix-item-body">{age}</span></li>
                    <li><span className="ellix-item-header">Origin</span><span className="ellix-item-body">Cheonan-si<br/>Chungcheongnam-do<br/>Republic of Korea</span></li>
                    <li><span className="ellix-item-header">Occupations</span><span className="ellix-item-body">Freelance Graphic Designer<br/>Freelance Interactive Media Designer</span></li>
                </ul>
                <h4 className="ellix-title">Personal<span>.</span></h4>
                <ul className="ellix-item-list">
                    <li><span className="ellix-item-header">Bloodtype</span><span className="ellix-item-body">B Rh+</span></li>
                    <li><span className="ellix-item-header">MBTI</span><span className="ellix-item-body">ENFJ</span></li>
                    <li><span className="ellix-item-header">Language</span><span className="ellix-item-body">Korean<br/>English<br/>Japanese</span></li>
                </ul>
                <h4 className="ellix-title">History<span>.</span></h4>
                <ul className="ellix-item-list">
                    <li><span className="ellix-item-header">2018</span><span className="ellix-item-body"><strong>“C” Media Co.</strong><br/>Video Editor</span></li>
                    <li><span className="ellix-item-header">2020-2023</span><span className="ellix-item-body"><strong>“W” Labor Lawfirm</strong><br/>Multimedia Designer<br/>Technical Manager<br/>HR Officer</span></li>
					<li><span className="ellix-item-header">2023-2024</span><span className="ellix-item-body"><strong>“I” Certified Real Estate Agency</strong><br/>Real Estate Marketer<br/>Associate Broker</span></li>
					<li><span className="ellix-item-header">2024</span><span className="ellix-item-body"><strong>“K” Agricultural Corporation</strong><br/>Multimedia Designer</span></li>
					<li><span className="ellix-item-header">2024-</span><span className="ellix-item-body"><strong>“E” Packaging and Branding Company</strong><br/>Multimedia Designer<br/>Marketer</span></li>
                </ul>
                <h4 className="ellix-title">Links<span>.</span></h4>
                <ul className="ellix-item-list">
                    <li><span className="ellix-item-header">e-mail</span><span className="ellix-item-body"><a href="mailto:ellixgraphic@gmail.com" target="_blank">ellixgraphic@gmail.com</a></span></li>
                    <li><span className="ellix-item-header">Platforms</span><span className="ellix-item-body"><a href="https://www.youtube.com/@ellixspace" target="_blank">Youtube</a> <a href="https://chzzk.naver.com/ead314f5fb11689edb4526d73a1698e6" target="_blank">Chzzk</a> <a href="https://www.twitch.tv/ellixspace" target="_blank">Twitch</a> <a href="https://www.tiktok.com/@ellixspace" target="_blank">TikTok</a></span></li>
                    <li><span className="ellix-item-header">SNS</span><span className="ellix-item-body"><a href="https://www.instagram.com/ellixspace/" target="_blank">Instagram</a> <a href="https://twitter.com/ellixspace" target="_blank">X</a> <a href="https://bsky.app/profile/ellixspace.com" target="_blank">Bluesky</a> <a href="https://www.threads.net/@ellixspace" target="_blank">Threads</a> <a href="https://open.spotify.com/user/31yg3lsjfzxeriv2k6wuf2upc47y" target="_blank">Spotify</a></span></li>
                    <li><span className="ellix-item-header">Games</span><span className="ellix-item-body"><a href="https://steamcommunity.com/id/ellixsteam/" target="_blank">Steam</a></span></li>
                </ul>
				<h4 className="ellix-title">More<span>.</span></h4>
				<ul className="ellix-item-list">
					<li><span className="ellix-item-header">T.M.I</span><span className="ellix-item-body"><a href="https://wiki.ellixspace.com/index.php/ELLIX" target="_blank">S.T.E.L.L.A Wiki</a></span></li>
				</ul>
            </div>
        </div>
    )
}